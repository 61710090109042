import classnames from 'classnames';
import { Switch, SwitchProps } from '@headlessui/react';
import { ElementType } from 'react';
import { BaseInput } from '../types';
import { Label } from './Label';

export interface ToggleProps
  extends BaseInput,
    Omit<SwitchProps<ElementType>, 'id'> {
  disabled?: boolean;
}

/**
 * @name Toggle
 * @description Simple toggle component taken from headless UI
 */
export const Toggle = (props: ToggleProps) => {
  const {
    className,
    checked = false,
    disabled = false,
    id,
    label,
    name,
    onChange,
  } = props;

  // Hooks

  // Setup

  // Handlers
  const onChangeHandler = (checked: boolean) => {
    if (onChange) onChange(checked);
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'flex flex-row items-center gap-2 hover:cursor-pointer',
        className,
      )}
    >
      <Switch
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={onChangeHandler}
      >
        <span
          className={`${
            checked ? 'bg-brand' : 'bg-gray-8'
          } flex h-5 w-9 rounded-3xl p-0.5`}
        >
          <span
            className={`block h-full w-1/2 transform rounded-full bg-white transition duration-300 ease-in-out ${
              checked ? 'translate-x-full' : ''
            }`}
          ></span>
        </span>
      </Switch>
      {!!label && (
        <Label className="hover:cursor-pointer" id={id}>
          {label}
        </Label>
      )}
      <input
        name={name}
        readOnly={true}
        type="hidden"
        value={String(checked)}
      />
    </div>
  );
};
