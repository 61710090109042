import classnames from 'classnames';
import { ButtonHTMLAttributes, cloneElement } from 'react';
import { Spinner } from './Spinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string | JSX.Element | (string | JSX.Element)[];
  color?: 'brand' | 'error' | 'success';
  intent?: 'primary' | 'secondary' | 'text';
  loading?: boolean;
  round?: boolean;
  shadow?: boolean;
  size?: 'small' | 'medium' | 'large';
}

/**
 * @name Button
 * @description A base button component
 */
export const Button = (props: ButtonProps) => {
  const {
    children,
    color = 'brand',
    className = '',
    disabled,
    intent = 'primary',
    loading = false,
    round = true,
    shadow = false,
    size = 'medium',
    type = 'button',
    ...rest
  } = props;

  // Hooks

  // Setup
  const classesButton = classnames(
    'ui-button',
    color,
    intent,
    size,

    // Props of the same name as the "class" used for styling
    { disabled, loading, round, shadow },

    // Allow for further customization
    className,
  );

  // Markup
  const renderChildren = (child: JSX.Element | string, index: number) => {
    const classes = typeof child === 'string' ? '' : child.props?.className;
    const classesLoading = classnames({ 'opacity-0': loading }, classes);

    /**
     * If the child is a simple string we have to wrap it so we can animate out
     */
    if (typeof child === 'string') {
      return (
        <div className={classesLoading} key={index}>
          {child}
        </div>
      );
    }

    const type = child.type.toString();

    /**
     * A fragment though, we can't provide/modify any classes so instead
     * we throw an error
     */
    if (type.includes('react.fragment')) {
      const message = `Don't pass a React Fragment to this component.`;
      throw new Error(message);
    }

    /**
     * Otherwise we clone the element and add our classes
     */
    return cloneElement(child, { className: classesLoading, key: index });
  };

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <button {...rest} className={classesButton} disabled={disabled} type={type}>
      {/* We wrap our contents so we're able to fade it in/out */}
      {Array.isArray(children)
        ? children.map(renderChildren)
        : renderChildren(children, 1)}

      {/* The loading indicator is "absolutely positioned" */}
      {loading && (
        <Spinner
          className={classnames(
            'aspect-1 absolute inset-1/2 left-1/2 top-1/2 h-full -translate-x-1/2 -translate-y-1/2 transition-opacity',
            {
              'opacity-0': !loading,
              'p-1.5': size === 'small',
              'p-2': size === 'medium',
              'p-2.5': size === 'large',
            },
          )}
        />
      )}
    </button>
  );
};
