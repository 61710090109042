import classnames from 'classnames';
import { Combobox } from '@headlessui/react';
import { ChangeEvent } from 'react';
import { AutocompleteProps } from '../types';
import { AutocompleteIcon } from './AutocompleteIcon';
import { Input } from './Input';
import { Label } from './Label';
import { SelectSingleOption } from './SelectSingle';

export interface AutocompleteSingleOption extends SelectSingleOption {
  [extra: string]: any;
}

export interface AutocompleteSingleProps<T extends AutocompleteSingleOption>
  extends AutocompleteProps {
  options: AutocompleteSingleOption[];
  selected?: T | null;
  setSelected: (item: T | null) => void;
}

/**
 * @name AutocompleteSingle
 * @description A Combobox component allowing text input with autocomplete.
 * Wraps headlessui's Combobox component
 */
export const AutocompleteSingle = <T extends AutocompleteSingleOption>(
  props: AutocompleteSingleProps<T>,
) => {
  const {
    className,
    disabled = false,
    id,
    label,
    loading,
    maxDropdownWidth,
    onChange,
    options = [],
    placeholder,
    selected,
    setSelected,
    showErrors,
    value,
  } = props;

  // Hooks

  // Setup
  const onClickClear = () => {
    setSelected(null);
    onChange?.({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  };

  // Handlers

  // Markup

  // 🔌 Short Circuits

  return (
    <div className={className}>
      {!!label && (
        <Label
          className="pb-1"
          id={id}
          onClick={(event) => event.preventDefault()}
        >
          {label}
        </Label>
      )}

      <Combobox
        disabled={disabled}
        multiple={false}
        onChange={setSelected}
        value={selected}
      >
        <Combobox.Button
          className="relative flex w-full"
          data-testid="autocomplete-input-button"
        >
          <Combobox.Input
            as={Input}
            className="truncate"
            classNameInput={classnames(
              value !== '' && 'pr-7',
              showErrors && 'content-danger ring-red/80 focus:ring-red pr-10',
              disabled && 'content-disabled',
            )}
            id={id}
            onChange={onChange}
            placeholder={placeholder}
            style={{ textOverflow: 'ellipsis' }}
            type="search"
            value={value}
          />

          <AutocompleteIcon
            disabled={disabled}
            loading={loading}
            onClear={onClickClear}
            selected={!!selected}
            value={!!value}
          />
        </Combobox.Button>

        <div className="relative">
          <Combobox.Options
            className="surface-primary absolute z-10 mt-1 max-h-60 min-w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style={{ maxWidth: maxDropdownWidth ?? '200%' }}
          >
            {options.length === 0 ? (
              <div className="content-primary relative cursor-default select-none px-4 py-2">
                Nothing found.
              </div>
            ) : (
              options.map((option) => (
                <Combobox.Option
                  className={({ active, selected }) =>
                    classnames(
                      active || selected
                        ? 'surface-accent text-white'
                        : 'content-primary',
                      'relative z-10 flex cursor-pointer select-none items-center gap-2 truncate px-3 py-2',
                    )
                  }
                  key={option.value}
                  value={option}
                >
                  {option.icon}
                  {option.name}
                  <span className="content-disabled text-xs">
                    {option.extra}
                  </span>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
};
