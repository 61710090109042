import classnames from 'classnames';
import { formatNumberToReadable } from '../utils/formatNumberToReadable';
import { Avatar, AvatarIconProps, AvatarImageProps } from './Avatar';

const multiplierFactorBySize = {
  l: 5,
  m: 4,
  s: 3,
  xl: 7,
  xs: 2,
  xxl: 8,
  xxs: 1,
};

export interface AvatarStackProps {
  avatars: (Omit<AvatarImageProps, 'size'> | Omit<AvatarIconProps, 'size'>)[];
  className?: string;
  limit?: number;
  size?: 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs';
  total?: number;
}

/**
 * @name AvatarStack
 * @description A stack of Avatar components with a max limit.
 */
export const AvatarStack = (props: AvatarStackProps) => {
  const {
    className,
    avatars,
    size = 'm',
    limit = 3,
    total = avatars.length,
  } = props;

  // Hooks

  // Setup
  const isListEmpty = !avatars.length;
  const countLabel = total > 0 ? formatNumberToReadable(total) : '';
  const multiplierFactor = multiplierFactorBySize[size];
  const borderWidth = `${Math.max(multiplierFactor - 1, 1)}px`;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={classnames(['relative flex', className])}>
      {avatars?.slice(0, limit).map((avatarProps, index) => {
        const { className: itemClassName, ...rest } = avatarProps;
        return (
          <Avatar
            {...rest}
            className={classnames(['border-white', itemClassName])}
            key={index}
            size={size}
            style={{
              borderWidth,
              marginLeft: index > 0 ? -multiplierFactor * 3 : 0,
            }}
          />
        );
      }) ?? []}
      {(total > 0 || isListEmpty) && (
        <Avatar
          className={classnames([
            'bg-brand-lighter text-brand border-white font-semibold',
          ])}
          icon={<div>{isListEmpty ? 0 : countLabel}</div>}
          size={size}
          style={{
            borderWidth,
            fontSize: `${multiplierFactor * 4}px`,
            marginLeft: -multiplierFactor * 3,
          }}
        />
      )}
    </div>
  );
};
