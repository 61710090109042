import { round } from 'lodash';

/**
 * @name formatNumberToReadable
 * @description formats large numbers with suffixes like "k", "M"
 */
export const formatNumberToReadable = (num: number) => {
  const suffixes = ['', 'k', 'M'];
  const suffixIndex = Math.floor(Math.log10(Math.abs(num)) / 3);
  const shortNum = round(num / Math.pow(10, suffixIndex * 3), 1);
  return `${shortNum}${suffixes[suffixIndex]}`;
};
