import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css?__remix_sideEffect__";

import {
  DateRangePicker,
  FocusedInputShape,
  DateRangePickerShape,
  RenderMonthProps } from
'react-dates';
import { useEffect, useState } from 'react';
import { Label } from './Label';
import { Errors } from './Errors';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type OptionalFields =
'focusedInput' |
'onFocusChange' |
'startDateId' |
'endDateId';

export type DatePickerRangeProps = PartialBy<
  DateRangePickerShape,
  OptionalFields> &

RenderMonthProps & {
  errors?: string | string[];
  label?: string;
};

/**
 * @name DatePickerRange
 * @external https://www.npmjs.com/package/react-dates
 * @description Simple date range picker that allows user to select a range of dates
 */
export const DatePickerRange = (props: DatePickerRangeProps) => {
  const {
    customArrowIcon = <span className="ml-1 mr-2">-</span>,
    hideKeyboardShortcutsPanel = true,
    numberOfMonths = 1,
    showDefaultInputIcon = true,
    label,
    errors,
    endDateId = 'end-date',
    startDateId = 'start-date',
    isOutsideRange = () => false,
    focusedInput: focusedInputProp,
    onFocusChange: onFocusChangeProp,
    ...rest
  } = props;

  // Hooks
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    focusedInputProp ?? null
  );

  // Setup
  const id = startDateId + endDateId;

  // Setup

  // Handlers
  const onFocusChange =
  onFocusChangeProp ?? (
  (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput);
  });

  // Markup

  // Life Cycle
  useEffect(() => {
    if (focusedInputProp !== focusedInput) {
      setFocusedInput(focusedInputProp ?? null);
    }
  }, [focusedInputProp]);

  // 🔌 Short Circuits

  return (
    <div className="flex flex-col">
      {label &&
      <Label className="mb-2" id={id}>
          {label}
        </Label>}


      <DateRangePicker
        customArrowIcon={customArrowIcon}
        endDateId={endDateId}
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel}
        isOutsideRange={isOutsideRange}
        numberOfMonths={numberOfMonths}
        onFocusChange={onFocusChange}
        showDefaultInputIcon={showDefaultInputIcon}
        startDateId={startDateId}
        {...rest} />


      {errors &&
      <Errors
        className="text-red m-0 mt-2"
        errors={errors}
        id={`${id}-error`} />}


    </div>);

};