export const drawer = {
  bottom: {
    child: {
      enterFrom: 'translate-y-full',
      enterTo: 'translate-y-0',
      leave: 'transform transition ease-in-out duration-300',
      leaveFrom: 'translate-y-0',
      leaveTo: 'translate-y-full',
    },
    panel: 'bottom-0 inset-x-0',
  },
  left: {
    child: {
      enterFrom: '-translate-x-full',
      enterTo: 'translate-x-0',
      leave: 'transform transition ease-in-out duration-300',
      leaveFrom: 'translate-x-0',
      leaveTo: '-translate-x-full',
    },
    panel: 'left-0 inset-y-0',
  },
  right: {
    child: {
      enterFrom: 'translate-x-full',
      enterTo: 'translate-x-0',
      leave: 'transform transition ease-in-out duration-300',
      leaveFrom: 'translate-x-0',
      leaveTo: 'translate-x-full',
    },
    panel: 'right-0 inset-y-0',
  },
  top: {
    child: {
      enterFrom: '-translate-y-full',
      enterTo: 'translate-y-0',
      leave: 'transform transition ease-in-out duration-300',
      leaveFrom: 'translate-y-0',
      leaveTo: '-translate-y-full',
    },
    panel: 'top-0 inset-x-0',
  },
} as const;
