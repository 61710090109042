import { RefObject, useMemo, useState } from 'react';
import { useOnMount } from './useOnMount';

/**
 * @name useOnScreen
 * @description Returns whether or not the given ref is in the viewport.
 */
export const useOnScreen = (ref: RefObject<HTMLElement>) => {
  // Hooks
  const [isIntersecting, setIntersecting] = useState(false);

  // Setup
  const observer = useMemo(
    () =>
      typeof document !== 'undefined'
        ? new IntersectionObserver(([entry]) =>
            setIntersecting(!!entry?.isIntersecting),
          )
        : undefined,
    [ref],
  );

  // Handlers

  // Life Cycle
  useOnMount(() => {
    if (!ref.current || !observer) return;
    observer.observe(ref.current);
    return () => observer.disconnect();
  });

  // 🔌 Short Circuits

  return isIntersecting;
};
