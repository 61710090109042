import { useCallback, useEffect } from 'react';

/**
 * @name useKeyPress
 * @description This hook is used to help make working with a few keyboard
 * events a bit easier down the road...
 */
export const useKeyPress = (
  callback: () => void,
  keyCode: string,
  meta = false,
) => {
  // Handlers
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { key, metaKey } = event;

      // Close our command palette
      if (!meta && key === keyCode) callback();

      if (meta && metaKey && key === keyCode) callback();
    },
    [callback, keyCode, meta],
  );

  // Life Cycle
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};
