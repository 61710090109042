import classnames from 'classnames';
import { Check, ThumbsUp, UserCircle } from '@phosphor-icons/react';

export interface FeedProps {
  className?: string;
}

export const Feed = (props: FeedProps) => {
  const { className } = props;

  // Hooks

  // Setup
  const timeline = [
    {
      content: 'Applied to',
      date: 'Sep 20',
      datetime: '2020-09-20',
      href: '?id=1',
      icon: UserCircle,
      iconBackground: 'bg-gray-4',
      id: 1,
      target: 'Front End Developer',
    },
    {
      content: 'Advanced to phone screening by',
      date: 'Sep 22',
      datetime: '2020-09-22',
      href: '?id=2',
      icon: ThumbsUp,
      iconBackground: 'bg-brand',
      id: 2,
      target: 'Bethany Blake',
    },
    {
      content: 'Completed phone screening with',
      date: 'Sep 28',
      datetime: '2020-09-28',
      href: '?id=3',
      icon: Check,
      iconBackground: 'bg-teal',
      id: 3,
      target: 'Martha Gardner',
    },
    {
      content: 'Advanced to interview by',
      date: 'Sep 30',
      datetime: '2020-09-30',
      href: '?id=4',
      icon: ThumbsUp,
      iconBackground: 'bg-brand',
      id: 4,
      target: 'Bethany Blake',
    },
    {
      content: 'Completed interview with',
      date: 'Oct 4',
      datetime: '2020-10-04',
      href: '?id=5',
      icon: Check,
      iconBackground: 'bg-teal',
      id: 5,
      target: 'Katherine Snyder',
    },
  ];

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={classnames('flow-root', className)}>
      <ul className="-mb-8" role="list">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  aria-hidden="true"
                  className="bg-gray-8 absolute left-4 top-4 -ml-px h-full w-0.5"
                />
              ) : null}

              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classnames(
                      event.iconBackground,
                      'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                    )}
                  >
                    <event.icon
                      aria-hidden="true"
                      className="h-5 w-5 text-white"
                    />
                  </span>
                </div>

                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-gray-5 text-sm">
                      {event.content}{' '}
                      <a className="text-gray-2 font-500" href={event.href}>
                        {event.target}
                      </a>
                    </p>
                  </div>
                  <div className="text-gray-5 whitespace-nowrap text-right text-sm">
                    <time dateTime={event.datetime}>{event.date}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
