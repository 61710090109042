import classnames from 'classnames';

export interface MarkdownProps {
  className?: string;
  content?: string | Record<any, any>;
}

/**
 * @name Markdown
 * @description This component will take a string or object and render it
 * as a code block.
 */
export const Markdown = (props: MarkdownProps) => {
  const { className, content = {} } = props;

  // Hooks

  // Setup
  const isString = typeof content === 'string';
  const value = isString ? content : JSON.stringify(content, undefined, 2);

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={classnames('markdown', className)}>
      <code>
        <pre>{value}</pre>
      </code>
    </div>
  );
};
