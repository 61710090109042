import classnames from 'classnames';
import { MagnifyingGlass, XCircle } from '@phosphor-icons/react';
import { ChangeEvent, InputHTMLAttributes } from 'react';

export interface InputSearchProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  className?: string;
  id: string;
}

/**
 * @name InputSearch
 * @external https://design.shiftsmart.com/design/input-search
 * @external https://www.figma.com/file/ZAmL4avRXRWZ1Goc70Dvt7/Web-library?type=design&node-id=2345%3A1015&mode=dev
 * @description Helps users find items by typing the relevant keywords.
 */
export const InputSearch = (props: InputSearchProps) => {
  const {
    autoComplete = 'off',
    className,
    disabled,
    id,
    onChange,
    value,
    ...rest
  } = props;

  // Hooks

  // Setup
  const showClearButton = !!value && !disabled;

  // Handlers
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  };

  const onClickClear = () => {
    onChange?.({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className="relative">
      <MagnifyingGlass
        aria-hidden="true"
        className={classnames(
          'text-gray-6 pointer-events-none absolute inset-y-0 left-0 my-auto ml-2 h-5 w-5',
          disabled && 'text-gray-7',
        )}
      />

      <input
        aria-label={id}
        autoComplete={autoComplete}
        className={classnames(
          'ring-gray-7 placeholder:text-gray-5 border-gray-7 block w-full rounded-md border px-8 py-1.5 sm:text-sm sm:leading-6',
          disabled && 'bg-gray-9 text-gray-7 border-0',
          className,
        )}
        disabled={disabled}
        id={id}
        name={id}
        onChange={onChangeHandler}
        type="text"
        value={value}
        {...rest}
      />

      {showClearButton && (
        <button
          className="text-gray-6 absolute inset-y-0 right-0 px-2 focus:text-blue-500"
          disabled={disabled}
          onClick={onClickClear}
        >
          <XCircle weight="bold" />
        </button>
      )}
    </div>
  );
};
