import classnames from 'classnames';
import { Button } from './Button';

export interface ModalFooterProps {
  children?: React.ReactElement | React.ReactElement[];
  className?: string;
  onClose?: () => void;
}

/**
 * @name ModalFooter
 * @description Simple modal footer that accepts children
 */
export const ModalFooter = (props: ModalFooterProps) => {
  const { className, onClose, children } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'bg-gray-9 flex flex-row rounded-b-lg p-4',
        className,
      )}
    >
      {onClose && (
        <Button intent="secondary" onClick={onClose}>
          Close
        </Button>
      )}
      <div className="flex flex-1 flex-row justify-end gap-x-2">{children}</div>
    </div>
  );
};
