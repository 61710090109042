import { Warning } from '@phosphor-icons/react';
import classNames from 'classnames';
import DatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import { Errors } from './Errors';
import { Label } from './Label';

import "react-datepicker/dist/react-datepicker.css?__remix_sideEffect__";

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
type RequiredFields = 'selected' | 'onChange';

export interface DateTimePickerProps extends
  WithRequired<ReactDatePickerProps, RequiredFields> {
  classNameInput?: string;
  errors?: string | string[];
  id: string;
  label?: string;
}

/**
 * @name DateTimePicker
 * @external https://www.npmjs.com/package/react-datepicker
 * @description Basic component for selecting a date time
 */
export const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    className,
    classNameInput,
    disabled,
    timeIntervals = 30,
    errors,
    id,
    label,
    ...rest
  } = props;

  // Hooks

  // Setup
  const showErrors = !!errors;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={classNames('flex flex-col', className)} id={id}>
      {label &&
      <Label className="mb-2 flex" id={id}>
          {label}
        </Label>}

      <DatePicker
        dateFormat="E, MMM do, h:mm a"
        disabled={disabled}
        showTimeSelect={true}
        timeIntervals={timeIntervals}
        {...rest}
        className={classNames(
          'border-secondary placeholder:text-gray-5 block w-full rounded-md px-3 py-1.5 sm:text-sm sm:leading-6',
          {
            'content-disabled surface-secondary border-tertiary': disabled,
            'text-red focus:ring-red border-danger': showErrors
          },
          classNameInput
        )} />

      {errors &&
      <div className="flex items-center py-1">
          <Warning className="text-error h-4 w-4" weight="fill" />
          <Errors
          className="text-error m-0 "
          errors={errors}
          id={`${id}-error`} />

        </div>}

    </div>);

};