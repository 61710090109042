import classnames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { Colors } from '@shiftsmartinc/style-guide';
import { Circle, X } from '@phosphor-icons/react';
import { getTagStyles } from '../utils/tag';

type UnusedColorKeys = 'black' | 'gray' | 'transparent' | 'white';

export interface TagProps {
  className?: string;
  color?: keyof Omit<Colors, UnusedColorKeys> | 'blue' | 'disabled';
  icon?: JSX.Element;
  label: string;
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  type?: 'dot' | 'pill';
}

/**
 * @name Tag
 * @external https://design.shiftsmart.com/design/tags
 * @external https://www.figma.com/file/ZAmL4avRXRWZ1Goc70Dvt7/Web-library?type=design&node-id=2345%3A1015&mode=dev
 * @description Helps signify the state of a value or a set of values in a
 * list, table row, or card.
 */
export const Tag = (props: TagProps) => {
  const {
    className,
    color = 'brand',
    icon,
    label,
    onClick,
    type = 'pill',
  } = props;

  // Hooks

  // Setup
  const isDot = type === 'dot';
  const isPill = type === 'pill';
  const styles = getTagStyles(color, isPill);

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <span
      className={classnames(
        'text-caption inline rounded-md',
        'inline-flex items-center gap-1',
        'whitespace-nowrap',
        { 'rounded-sm px-2 py-1': isPill },
        styles,
        className,
      )}
    >
      {/* Conditional layout items */}
      {isDot && <Circle className="h-2 w-2" weight="fill" />}
      {isPill && !!icon && icon}

      {/* We will always have a label */}
      {label}

      {/* All we care about is the onClick being there */}
      {isPill && !!onClick && (
        <button onClick={onClick}>
          <X className="h-3 w-3" weight="bold" />
        </button>
      )}
    </span>
  );
};
