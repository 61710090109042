import classnames from 'classnames';
import { CaretDown } from '@phosphor-icons/react';
import { colors } from '@shiftsmartinc/style-guide';

export interface TagLogicalOperatorProps {
  allowToggle?: boolean;
  className?: string;
}

export const TagLogicalOperator = (props: TagLogicalOperatorProps) => {
  const { className, allowToggle = false } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <span
      className={classnames(
        'bg-gray-8 font-500 text-gray-1 mb-1 mr-1 inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs',
        className,
      )}
    >
      or
      {allowToggle && (
        <button className="relative -mr-1 h-3.5 w-3.5 rounded-sm" type="button">
          <CaretDown className="h-4 w-4" color={colors.brand.DEFAULT} />
          <span className="absolute -inset-1"></span>
        </button>
      )}
    </span>
  );
};
