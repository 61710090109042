import { useContext, useEffect } from 'react';
import { ModalContext } from '../context/ModalContext';

/**
 * @name useModal
 * @description This hook interacts with our ModalContext and handles the
 * opening and closing of modals. We track modals by an id, which is passed
 * to the hook.
 */
export const useModal = (id: string, closeOnUnmount = true) => {
  // Hooks
  const context = useContext(ModalContext);

  // Setup

  // Handlers

  // Life Cycle
  useEffect(() => {
    return () => {
      if (!context || !closeOnUnmount) return;

      context.closeModal(id);
    };
  }, []);

  // 🔌 Short Circuits
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return {
    closeModal: () => context.closeModal(id),
    isModalOpen: context.openModals.has(id),
    openModal: () => context.openModal(id),
  };
};
