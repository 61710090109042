import { NavLink } from '@remix-run/react';
import { Cell, Row, Table, flexRender } from '@tanstack/react-table';
import classnames from 'classnames';
import { ClassNameComponents } from '../utils/table';

export interface TableRowProps<Item> {
  active?: boolean;
  classNameComponents?: Pick<ClassNameComponents, 'tbodyCell' | 'tbodyRow'>;
  getRowUrl?: (row: Row<Item>) => string;
  row: Row<Item>;
  table: Table<Item>;
}

/**
 * @name TableRow
 * @description A table row
 */
export const TableRow = <Item extends { uuid: string }>(
  props: TableRowProps<Item>,
) => {
  const { getRowUrl, row, table, classNameComponents, active } = props;

  // Hooks

  // Setup
  const { tbodyCell, tbodyRow } = classNameComponents ?? {};

  const rowUrl = getRowUrl?.(row);

  const selected = row.getIsSelected();
  const cells = row.getVisibleCells();
  const isSubRow = row.getParentRow() !== undefined;
  const isPinned = row.getIsPinned();

  // Handlers

  // Markup
  const renderCell = (cell: Cell<Item, unknown>) => {
    const { column, getContext } = cell;
    const cellMarkup = flexRender(column.columnDef.cell, getContext());

    if (!rowUrl) return cellMarkup;

    return (
      <NavLink className="flex h-full w-full items-center" to={rowUrl}>
        {cellMarkup}
      </NavLink>
    );
  };

  const position = row.getIsPinned() ? 'sticky' : undefined;

  const top =
    row.getIsPinned() === 'top'
      ? `${row.getPinnedIndex() * 48 + 48}px`
      : undefined;

  const bottom =
    row.getIsPinned() === 'bottom'
      ? `${(table.getBottomRows().length - 1 - row.getPinnedIndex()) * 48}px`
      : undefined;

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <tr
      className={classnames(
        { active, 'bg-gray-9': isSubRow || isPinned, selected },
        {
          'hover:bg-gray-8 cursor-pointer transition transition-colors':
            getRowUrl,
        },
        tbodyRow,
      )}
      style={{ bottom, position, top }}
    >
      {cells.map((cell) => {
        const meta = cell.column.columnDef.meta;
        const tbodyCellFromColumn = meta?.classNameComponents?.tbodyCell;

        return (
          <td
            className={classnames(tbodyCell, tbodyCellFromColumn)}
            key={cell.id}
          >
            {renderCell(cell)}
          </td>
        );
      })}
    </tr>
  );
};
