import { CaretDown, CaretRight, CaretUp } from '@phosphor-icons/react';
import { HeaderContext } from '@tanstack/react-table';
import { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Button } from './Button';

export interface TableHeaderSortingProps<Item>
  extends PropsWithChildren<HeaderContext<Item, unknown>> {}

/**
 * @name TableHeaderSorting
 * @description A table header with a button to toggle between sort states
 * and a caret icon to indicate the current sort state
 */
export const TableHeaderSorting = <Item extends { uuid: string }>(
  props: TableHeaderSortingProps<Item>,
) => {
  const { column, children } = props;

  // Hooks

  // Setup
  const isSorted = column.getIsSorted();
  const sortIndex = column.getSortIndex();
  const sortIndexLabel = sortIndex >= 0 ? `${sortIndex + 1}` : '';

  // Handlers
  const onClick = () => column.toggleSorting(undefined, true);

  // Markup
  const Icon = !isSorted
    ? CaretRight
    : isSorted === 'asc'
    ? CaretUp
    : CaretDown;

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className="flex items-center gap-1">
      {children}

      <Button
        className={classnames('!font-500 !gap-1 !p-0 !text-xs')}
        data-testid={`sort-button-${column.id}`}
        intent="text"
        onClick={onClick}
      >
        <Icon />

        {sortIndexLabel}
      </Button>
    </div>
  );
};
