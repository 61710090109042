import { CaretDown, CaretRight } from '@phosphor-icons/react';
import { CellContext } from '@tanstack/react-table';
import classnames from 'classnames';
import { Button } from './Button';

export interface TableCellExpandingProps<Item>
  extends CellContext<Item, unknown> {}

/**
 * @name TableCellExpanding
 * @description A table cell with a button to toggle row expansion state and
 * a caret icon to indicate the row's current expansion state
 */
export const TableCellExpanding = <Item extends { uuid: string }>(
  props: TableCellExpandingProps<Item>,
) => {
  const { row } = props;

  // Hooks

  // Setup
  const canExpand = row.getCanExpand();
  const isExpanded = row.getIsExpanded();
  const subRowCount = row.subRows.length ? `${row.subRows.length}` : '';

  // Handlers
  const toggleExpanded = () => row.toggleExpanded();

  // Markup
  const Icon = isExpanded ? CaretDown : CaretRight;

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className="flex h-full w-full cursor-default items-center justify-center"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      {canExpand && (
        <Button
          className={classnames('!gap-1 !rounded-md !p-1', {
            'bg-gray-8': isExpanded,
            'bg-gray-9': !isExpanded,
          })}
          data-testid={`expand-button-${row.id}`}
          intent="text"
          onClick={toggleExpanded}
          size="small"
        >
          <Icon />

          {subRowCount}
        </Button>
      )}
    </div>
  );
};
