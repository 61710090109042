import { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react';
import { UploadSimple as UploadSimpleIcon } from '@phosphor-icons/react';
import classnames from 'classnames';

export interface UploadSimpleProps
  extends InputHTMLAttributes<HTMLInputElement> {
  description?: string;
  disabled?: boolean;
  label?: string;
  title?: string;
}

/**
 * @name UploadSimple
 * @external https://design.shiftsmart.com/design/upload
 * @external https://www.figma.com/file/pY05zN0RpIhDwNb0slAe8f/Shiftsmart-Design-System?type=design&node-id=215-10006&mode=dev
 * @description A simple reusable upload component.
 */
export const UploadSimple = (props: UploadSimpleProps) => {
  const {
    className,
    description,
    disabled,
    label,
    multiple = true,
    onChange,
    title,
    ...rest
  } = props;

  // Hooks
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  // Setup
  const hasUploadedFile = files.length > 0;

  // Handlers
  const onBlurHandler = () => {
    setIsClicked(false);
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (disabled || !files) return;

    onChange?.(event);
    setFiles(Array.from(files));
  };

  const onClickHandler = () => {
    if (disabled) return;

    setIsClicked(true);
    inputRef.current?.click();
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div>
      {label && <label className="pointer-events-none">{label}</label>}
      <div
        className={classnames('flex-col rounded-md', className, {
          'border-brand cursor-pointer border bg-white':
            isClicked && !disabled && !hasUploadedFile,
          'border-brand-dark cursor-pointer border bg-blue-100':
            isClicked && !disabled && hasUploadedFile,
          'border-gray-6 cursor-pointer border bg-white':
            !disabled && !isClicked,
          'border-gray-7 pointer-events-none bg-white': disabled,
        })}
        onBlur={onBlurHandler}
        onClick={onClickHandler}
      >
        <input
          {...rest}
          className="hidden"
          data-testid="upload-simple"
          disabled={disabled}
          multiple={multiple}
          onChange={onChangeHandler}
          ref={inputRef}
          type="file"
        />
        <div>
          <div className="flex h-full flex-row items-center justify-center p-2">
            <div
              className={classnames('mr-1 flex h-4 w-4 items-center', {
                'text-brand': !disabled && isClicked && !hasUploadedFile,
                'text-brand-dark': !disabled && isClicked && hasUploadedFile,
                'text-gray-1': !disabled && !isClicked,
                'text-gray-7': disabled,
              })}
            >
              <UploadSimpleIcon size={16} weight="bold" />
            </div>
            <span
              className={classnames('font-500 text-sm', {
                'text-brand': !disabled && isClicked && !hasUploadedFile,
                'text-brand-dark': !disabled && isClicked && hasUploadedFile,
                'text-gray-1': !disabled && !isClicked,
                'text-gray-7': disabled,
              })}
            >
              {title}
            </span>
          </div>
        </div>
      </div>

      {description && (
        <div
          className={classnames('text-gray-5 pointer-events-none', className)}
        >
          {description}
        </div>
      )}
    </div>
  );
};
