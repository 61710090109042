import classnames from 'classnames';
import { X } from '@phosphor-icons/react';
import { colors } from '@shiftsmartinc/style-guide';

export interface TagItemProps {
  className?: string;
  id: string;
  label: string;
  removeItem?: () => void;
}

export const TagItem = (props: TagItemProps) => {
  const { className, label, removeItem } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <span
      className={classnames(
        'bg-brand-lighter font-500 text-brand mb-1 mr-1 inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs',
        className,
      )}
      data-testid={label}
    >
      {label}
      {removeItem && (
        <button
          className="relative -mr-1 h-3.5 w-3.5 rounded-sm"
          onClick={removeItem}
          type="button"
        >
          <X className="h-4 w-4" color={colors.brand.DEFAULT} />
          <span className="absolute -inset-1"></span>
        </button>
      )}
    </span>
  );
};
