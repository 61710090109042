import * as React from 'react';
import { ModalContext } from './ModalContext';

export interface ModalProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

/**
 * @name ModalProvider
 * @description We store an array of strings in a "Set" to keep track of
 * which modals are open. We use the "Set" because it guarantees that we
 * will not have duplicate values.
 */
export const ModalProvider = (props: ModalProviderProps) => {
  const { children } = props;

  // Hooks
  const [openModals, setOpenModals] = React.useState<Set<string>>(new Set());

  // Setup

  // Handlers
  const closeModal = (id: string) => {
    openModals.delete(id);
    setOpenModals(new Set(openModals.values()));
  };

  const openModal = (id: string) => {
    openModals.add(id);
    setOpenModals(new Set(openModals.values()));
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <ModalContext.Provider value={{ closeModal, openModal, openModals }}>
      <>{children}</>
    </ModalContext.Provider>
  );
};
