import { CellContext } from '@tanstack/react-table';
import { MouseEvent } from 'react';
import { Checkbox } from './Checkbox';

export interface TableCellCheckboxProps<Item>
  extends CellContext<Item, unknown> {
  className?: string;
}

/**
 * @name TableCellCheckbox
 * @description A cell to display a checkbox for selecting a row
 */
export const TableCellCheckbox = <Item extends { uuid: string }>(
  props: TableCellCheckboxProps<Item>,
) => {
  const { row } = props;

  // Hooks

  // Setup
  const isSelected = row.getIsSelected();
  const canSelect = row.getCanSelect();
  const toggleSelectedHandler = row.getToggleSelectedHandler();

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className="flex h-full w-full cursor-default items-center justify-center"
      onClick={(event) => event.stopPropagation()}
    >
      <Checkbox
        checked={isSelected}
        classNameInput="h-6 w-6"
        data-testid={`row-checkbox-${row.id}`}
        disabled={!canSelect}
        id={`checkbox-${row.id}`}
        onChange={toggleSelectedHandler}
        onClick={(event: MouseEvent) => event.stopPropagation()}
      />
    </div>
  );
};
