import classnames from 'classnames';

export interface ErrorsProps {
  className?: string;
  errors?: string | string[];
  id: string;
}

export const Errors = (props: ErrorsProps) => {
  const { className, errors = [], id } = props;

  // Hooks

  // Setup
  const isArray = Array.isArray(errors);
  const isList = isArray && errors.length > 1;

  // Handlers

  // Markup
  const renderError = (error: string, index: number) => {
    return (
      <li className="text-sm" key={`${index}-${error}`}>
        {error}
      </li>
    );
  };

  // Life Cycle

  // 🔌 Short Circuits
  if (!errors || !errors.length) return null;

  return (
    <ul
      className={classnames(
        'space-y-1',
        {
          'list-disc pl-5': isList,
          'pl-2': !isList,
        },
        className,
      )}
      id={`${id}-error`}
      role="list"
    >
      {isArray ? errors.map(renderError) : renderError(errors, 0)}
    </ul>
  );
};
