import {
  type TableOptions,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from '@tanstack/react-table';

export interface useTableProps<Item>
  extends Omit<TableOptions<Item>, 'getCoreRowModel'> {
  total: number;
}

/**
 * @name useTable
 * @description A wrapper around tanstack-table's useReactTable hook
 */
export const useTable = <Item extends { uuid: string }>(
  props: useTableProps<Item>,
) => {
  const { state, total, ...rest } = props;

  // Setup
  const pageSize = state?.pagination?.pageSize;

  // Hooks
  const table = useReactTable({
    enableColumnPinning: true,
    enableExpanding: true,
    enableGrouping: true,
    enableHiding: true,
    enableMultiRemove: true,
    enableMultiRowSelection: true,
    enableMultiSort: true,
    enableRowPinning: true,
    enableRowSelection: true,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    /**
     * Lets tanstack-table handle Grouping/Expansion state,
     * including adding and removing subRows upon expansion/collapse.
     */
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: (item: Item) => item.uuid,

    /**
     * Pagination, Filtering, Sorting, Row Selection, and Column Visibility
     * state are stored in the URL QueryString, though tanstack-table's
     * methods onPaginationChange, onSortChange, and onRowSelectionChange
     * are still used to update those states.
     */
    manualExpanding: false,
    manualFiltering: false,
    manualGrouping: false,
    manualPagination: !!state?.pagination || undefined,
    manualSorting: !!state?.sorting || undefined,

    pageCount: pageSize ? Math.ceil(total / pageSize) : undefined,
    state,
    ...rest,
  });

  // Setup

  // Handlers

  // Life Cycle

  // 🔌 Short Circuits

  return table;
};
