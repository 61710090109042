import type { ListboxProps } from '@headlessui/react';
import { Check, CaretDown, Info, X, Warning } from '@phosphor-icons/react';
import { colors } from '@shiftsmartinc/style-guide';
import {
  ElementType,
  Fragment,
  useState,
  useEffect,
  MouseEventHandler,
} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Tooltip } from 'react-tooltip';
import classnames from 'classnames';
import { Errors } from '../components/Errors';
import { BaseInput } from '../types';
import { Spinner } from './Spinner';

export interface SelectSingleOption {
  extra?: string;
  icon?: JSX.Element;
  name: string;
  value: string;
}

export type SelectSingleBase = BaseInput & {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  options: SelectSingleOption[];
  placeholder?: string;
  required?: boolean;
  tooltip?: string;
};

export type SelectSingleListboxProps = ListboxProps<
  ElementType,
  SelectSingleOption,
  SelectSingleOption
>;

export type SelectSingleProps = {
  defaultValue?: SelectSingleOption;
  onBlur?: SelectSingleListboxProps['onBlur'];
  onChange?: SelectSingleListboxProps['onChange'];
  value?: SelectSingleOption;
} & SelectSingleBase;

/**
 * @name SelectSingle
 * @description A select dropdown that allows a single selection
 */
export const SelectSingle = (props: SelectSingleProps) => {
  const {
    className,
    errors,
    id,
    loading = false,
    required = false,
    label,
    onChange,
    options,
    placeholder = 'Select an option',
    tooltip,
    defaultValue: defaultValueProp,
    value: valueProp,
    ...rest
  } = props;

  // Hooks
  const [selected, setSelected] = useState<SelectSingleOption>(
    valueProp ?? defaultValueProp ?? { name: '', value: '' },
  );

  // Setup
  const isSelected = !!selected.value;

  // Handlers
  const onChangeHandler = (selection: SelectSingleProps['value']) => {
    const isDeselect = !selection || selection.value === selected.value;
    const newSelection = isDeselect ? { name: '', value: '' } : selection;

    setSelected(newSelection);
    if (onChange) onChange(newSelection);
  };

  const onClickClear: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onChangeHandler(undefined);
  };

  // Markup
  const getLabel = () => {
    if (!selected.value) return placeholder;
    return selected.icon ? (
      <span className="flex items-center gap-2 truncate font-normal">
        {selected.icon}
        {selected.name}
      </span>
    ) : (
      selected.name
    );
  };

  const inputIcon = selected.value ? (
    <button onClick={onClickClear}>
      <X className="text-gray-4 h-5 w-5" />
    </button>
  ) : (
    <CaretDown
      aria-hidden="true"
      className={classnames('h-5 w-5', errors ? 'text-error' : 'text-gray-4')}
    />
  );

  // Life Cycle
  useEffect(() => {
    setSelected(valueProp ?? defaultValueProp ?? { name: '', value: '' });
  }, [valueProp, defaultValueProp]);

  // 🔌 Short Circuits

  return (
    <div className={className}>
      {/* The UI Component */}
      <Listbox {...rest} by="value" onChange={onChangeHandler} value={selected}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="mb-2 flex items-end gap-1 align-bottom">
                {tooltip && (
                  <span
                    data-tooltip-content={tooltip}
                    data-tooltip-id={`${id}-tooltip`}
                  >
                    <Info color={colors.gray[5]} size={18} />
                    <Tooltip id={`${id}-tooltip`} place="top" />
                  </span>
                )}
                <span className="font-500 text-gray-1 text-sm">
                  {label}
                  {required && <span className="text-error">*</span>}
                </span>

                {isSelected && (
                  <span
                    className="text-gray-5 cursor-pointer text-xs"
                    onClick={() => {
                      onChangeHandler({ name: '', value: '' });
                    }}
                  >
                    Clear
                  </span>
                )}
              </Listbox.Label>
            )}

            <div className="relative">
              <Listbox.Button
                className={classnames(
                  'background-main',
                  'text-gray-1 focus:ring-brand relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left',
                  'shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 sm:text-sm sm:leading-6',
                  {
                    'ring-error': !!errors,
                    'ring-gray-7 ': !errors,
                  },
                )}
              >
                <span
                  className={classnames('block truncate', {
                    'text-gray-1': isSelected,
                    'text-gray-5': !isSelected,
                  })}
                >
                  {getLabel()}
                </span>
                <span
                  className={classnames(
                    'absolute inset-y-0 right-0 flex items-center pr-2',
                    {
                      'pointer-events-none': loading,
                    },
                  )}
                >
                  {loading ? (
                    <Spinner className="h-full w-full pb-2.5 pt-2.5" />
                  ) : (
                    inputIcon
                  )}
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <Listbox.Options className="background-main absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      className={({ active, selected }) =>
                        classnames(
                          active || selected
                            ? 'bg-brand text-white'
                            : 'text-gray-1',
                          'relative cursor-pointer select-none py-2 pl-3 pr-9',
                        )
                      }
                      key={option.value}
                      value={option}
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classnames(
                              active || selected ? 'font-700' : 'font-normal',
                              'flex items-center gap-2 truncate',
                            )}
                          >
                            {option.icon}
                            {option.name}
                            <span className="text-gray-5 text-xs">
                              {option.extra}
                            </span>
                          </span>

                          {(active || selected) && (
                            <span
                              className={classnames(
                                selected ? 'text-white' : 'text-brand',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <Check aria-hidden="true" className="h-5 w-5" />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {/* Store the value in a readonly, hidden input */}
      <input
        data-testid={id}
        name={id}
        readOnly={true}
        type="hidden"
        value={selected.value}
      />
      {/* Render any errors */}
      {errors && (
        <div className="flex items-center py-1">
          <Warning className="text-error h-4 w-4" weight="fill" />
          <Errors
            className="text-error m-0 "
            errors={errors}
            id={`${id}-error`}
          />
        </div>
      )}
    </div>
  );
};
