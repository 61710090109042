import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { Table } from '@tanstack/react-table';
import classnames from 'classnames';
import { getPageIndices } from '../utils/table';
import { Button } from './Button';

export interface TableFooterProps<Item> {
  className?: string;
  isInfiniteScroll?: boolean;
  loading?: boolean;
  table: Table<Item>;
  total: number;
}

/**
 * @name TableFooter
 * @description A table footer showing pagination information and actions
 */
export const TableFooter = <Item extends object>(
  props: TableFooterProps<Item>,
) => {
  const { table, total, className, isInfiniteScroll, loading = false } = props;

  // Setup
  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();

  const firstRowIndex = Math.min(total, pageIndex * pageSize + 1);
  const lastRowIndex = Math.min(total, (pageIndex + 1) * pageSize);

  const canPreviousPage = table.getCanPreviousPage();
  const canNextPage = table.getCanNextPage();

  const pageIndices = getPageIndices(pageCount, pageIndex);
  const { firstPageIndex, lastPageIndex } = pageIndices;

  const pageIndicesShown = Array.from(
    { length: lastPageIndex - firstPageIndex },
    (_, i) => firstPageIndex + i,
  );

  // Handlers

  // Markup
  const paginationLabel = (
    <>
      Showing <b>{firstRowIndex}</b> to <b>{lastRowIndex}</b> of <b>{total}</b>
      {loading && ', loading more...'}
    </>
  );

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'flex h-full items-center justify-between',
        className,
      )}
    >
      <span
        className="font-400 text-center align-middle text-sm leading-5"
        data-testid="pagination-text"
      >
        {paginationLabel}
      </span>
      {!isInfiniteScroll && (
        <div className="flex pr-6">
          <Button
            className="disabled:!border-gray-7 hover:text-brand z-0 -mr-px flex h-9 w-9 items-center justify-center gap-2 !rounded-r-none rounded-l-lg bg-white !px-2 !py-1 hover:z-10"
            data-testid="prev-page-button"
            disabled={!canPreviousPage}
            intent="secondary"
            onClick={table.previousPage}
          >
            <CaretLeft size={14} weight="bold" />
          </Button>

          {pageIndicesShown.map((pageIndexShown) => {
            const disabled = pageIndexShown === pageIndex;
            const label = `${pageIndexShown + 1}`;

            const onClick = () => table.setPageIndex(pageIndexShown);

            return (
              <Button
                className="!font-500 disabled:!border-gray-7 z-0 -mx-px flex h-9 w-9 items-center justify-center gap-2 !rounded-none bg-white !text-sm hover:z-10"
                data-testid={`page-button-${label}`}
                disabled={disabled}
                intent="secondary"
                key={pageIndexShown}
                onClick={onClick}
              >
                {label}
              </Button>
            );
          })}
          <Button
            className="disabled:!border-gray-7 hover:text-brand z-0 -ml-px flex h-9 w-9 items-center justify-center gap-2 !rounded-l-none rounded-r-lg bg-white !px-2 !py-1 hover:z-10"
            data-testid="next-page-button"
            disabled={!canNextPage}
            intent="secondary"
            onClick={table.nextPage}
          >
            <CaretRight size={14} weight="bold" />
          </Button>
        </div>
      )}
    </div>
  );
};
