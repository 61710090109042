import { Dialog, Transition } from '@headlessui/react';
import * as React from 'react';
import classnames from 'classnames';
import { Fragment } from 'react';
import { drawer } from '../data/drawer';

export interface DrawerProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  onClose: () => void;
  open: boolean;
  position?: keyof typeof drawer;
  unmount?: boolean;
}

/**
 * @name Drawer
 * @description This is a drawer component that can be used to display content
 * on the "left", "right", "top", or "bottom" side of the screen. It uses
 * the "@headlessui/react" Dialog and Transition components to handle the
 * animation and underlay. The "position" prop determines which side of the
 * screen the drawer will appear on and it simply takes the children you
 * pass to it and renders them inside the drawer.
 */
export const Drawer = (props: DrawerProps) => {
  const {
    children,
    position = 'right',
    open,
    className,
    onClose,
    unmount = true,
  } = props;

  // Hooks

  // Setup
  const settings = drawer[position];

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <Transition.Root as={Fragment} show={open} unmount={unmount}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={onClose}
        unmount={unmount}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          unmount={unmount}
        >
          <div className="bg-gray-5 fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              unmount={unmount}
              {...settings.child}
            >
              <Dialog.Panel
                className={classnames(
                  'background-main fixed overflow-visible shadow-xl transition-all',
                  settings.panel,
                  className,
                )}
              >
                <>{children}</>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
