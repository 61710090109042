import { createColumnHelper } from '@tanstack/react-table';
import classnames from 'classnames';
import { TableProps } from '../components/Table';
import { TableHeaderSorting } from '../components/TableHeaderSorting';
import { TableCellCheckbox } from '../components/TableCellCheckbox';
import { TableHeaderCheckbox } from '../components/TableHeaderCheckbox';
import { TableCellPinning } from '../components/TableCellPinning';
import { TableHeaderPinning } from '../components/TableHeaderPinning';
import { TableCellExpanding } from '../components/TableCellExpanding';
import { TableHeaderExpanding } from '../components/TableHeaderExpanding';

export const getSelectingColumn = <Item extends { uuid: string }>() =>
  createColumnHelper<Item>().display({
    cell: (props) => <TableCellCheckbox {...props} />,
    header: (props) => <TableHeaderCheckbox {...props} />,
    id: 'selecting',
    meta: {
      classNameComponents: {
        tbodyCell: 'left-0',
        theadCell: 'left-0',
      },
    },
  });

export const getExpandingColumn = <Item extends { uuid: string }>() =>
  createColumnHelper<Item>().display({
    cell: (props) => <TableCellExpanding {...props} />,
    header: (props) => <TableHeaderExpanding {...props} />,
    id: 'expanding',
    meta: {
      classNameComponents: {
        tbodyCell: 'left-14',
        theadCell: 'left-14',
      },
    },
  });

export const getPinningColumn = <Item extends { uuid: string }>() =>
  createColumnHelper<Item>().display({
    cell: (props) => <TableCellPinning {...props} />,
    header: (props) => <TableHeaderPinning {...props} />,
    id: 'pinning',
    meta: {
      classNameComponents: {
        tbodyCell: 'left-28',
        theadCell: 'left-28',
      },
    },
  });

export const featureColumnIds = ['selecting', 'expanding', 'pinning'];

export function getColumns<Item extends { uuid: string }>(
  props: TableProps<Item>,
) {
  const {
    columns: columnsProp,
    onRowSelectionChange,
    onRowPinningChange,
    getSubRows,
  } = props;

  const columns = columnsProp.map((column) => {
    if (column.enableSorting) {
      const header = column.header;
      column.header = (props) => (
        <TableHeaderSorting {...props}>
          {typeof header === 'function' ? header(props) : header}
        </TableHeaderSorting>
      );
      column.enableSorting = false;
    }
    return column;
  });

  const featureColumnsUnstyled = [
    ...(onRowSelectionChange ? [getSelectingColumn<Item>()] : []),
    ...(onRowPinningChange ? [getPinningColumn<Item>()] : []),
    ...(getSubRows ? [getExpandingColumn<Item>()] : []),
  ];
  const featureColumns = featureColumnsUnstyled.map((featureColumn, i) => {
    const cellStyles = classnames('sticky z-10 w-8 !p-0 [&>*]:pr-2', {
      '[&>*]:border-r-gray-8 [&>*]:border-r':
        i === featureColumnsUnstyled.length - 1,
      '[&>*]:pl-6': i === 0,
    });
    featureColumn.meta = {
      ...featureColumn.meta,
      classNameComponents: {
        ...featureColumn.meta?.classNameComponents,
        tbodyCell: classnames(
          featureColumn.meta?.classNameComponents?.tbodyCell,
          cellStyles,
        ),
        theadCell: classnames(
          featureColumn.meta?.classNameComponents?.theadCell,
          cellStyles,
        ),
      },
    };

    return featureColumn;
  });

  columns.unshift(...featureColumns);

  return { columns, featureColumns };
}

export const pageButtonCount = 4;

export const getPageIndices = (pageCount: number, pageIndex: number) => {
  if (pageCount <= pageButtonCount) {
    return {
      firstPageIndex: 0,
      lastPageIndex: pageCount,
    };
  }

  if (pageIndex < pageCount - pageButtonCount) {
    return {
      firstPageIndex: pageIndex,
      lastPageIndex: Math.min(pageIndex + pageButtonCount, pageCount),
    };
  } else {
    return {
      firstPageIndex: Math.max(pageCount - pageButtonCount, 0),
      lastPageIndex: pageCount,
    };
  }
};

export interface ClassNameComponents {
  table?: string;
  tableFooter?: string;
  tbody?: string;
  tbodyCell?: string;
  tbodyRow?: string;
  tfoot?: string;
  tfootCell?: string;
  tfootRow?: string;
  thead?: string;
  theadCell?: string;
  theadRow?: string;
}
