import { useState } from 'react';
import classnames from 'classnames';

interface BaseAvatarProps {
  className?: string;
  notificationDot?: 'error' | 'info' | 'success' | 'warning' | false;
  round?: boolean;
  size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  statusIcon?: JSX.Element;
  /**
   * Used for dynamic css properties
   * For basic styling use className props with tailwind classes
   */
  style?: React.CSSProperties;
}

export type AvatarImageProps = BaseAvatarProps & {
  icon?: never;
  name: string;
  src: string;
};

export type AvatarIconProps = BaseAvatarProps & {
  icon: JSX.Element;
  name?: never;
  src?: never;
};

export type AvatarProps = AvatarImageProps | AvatarIconProps;

/**
 * @name Avatar
 * @description We use this rounded image all over the place so we've made
 * it a component. This Avatar image does not require an image "src" but it
 * does require a name, which is used to render initials if/when there is no
 * image. To size the image, just supply a height, width, and supply a
 * font-size using TailwindCSS.
 */
export const Avatar = (props: AvatarProps) => {
  const {
    className,
    icon,
    name,
    notificationDot,
    round = true,
    src,
    statusIcon,
    size,
    style = {},
  } = props;

  // Hooks
  const [failed, setFailed] = useState(false);

  // Setup
  const isImageAvatar = !props.icon;
  const hasValidImage = src && !failed;
  const [first = '', last = ''] = name ? name.split(' ') : [];

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  const avatarSizesClassnames = {
    xxs: 'h-4 w-4 text-[8px]',
    xs: 'h-6 w-6 text-2xs',
    s: 'h-8 w-8 text-sm',
    m: 'h-12 w-12 text-xl',
    l: 'h-16 w-16 text-2xl',
    xl: 'h-24 w-24 text-3xl',
    xxl: 'h-32 w-32 text-5xl',
  };
  /* eslint-enable sort-keys-fix/sort-keys-fix */

  const sizeClassnames = size && avatarSizesClassnames[size];

  // Handlers

  // Markup
  const onErrorHandler = () => {
    setFailed(true);
  };

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'aspect-1 relative flex items-center',
        sizeClassnames ?? '',
        {
          'bg-brand': failed || !hasValidImage,
          'rounded-full': round,
        },
        className,
      )}
      data-testid="avatar"
      style={style}
    >
      {isImageAvatar && hasValidImage && (
        <img
          alt={name}
          className="h-full w-full rounded-full"
          loading="lazy"
          onError={onErrorHandler}
          src={src}
        />
      )}

      {isImageAvatar && !hasValidImage && (
        <div className="flex h-full w-full items-center justify-center">
          <div className="text-white" data-testid="avatar-initials">
            {first[0]}
            {last[0]}
          </div>
        </div>
      )}

      {!isImageAvatar && (
        <div className="flex h-full w-full items-center justify-center">
          {icon}
        </div>
      )}

      {!!notificationDot && (
        <div className="absolute right-0 top-0 flex h-[28%] w-[28%] items-center justify-center rounded-full bg-white">
          <div
            className={classnames([
              'h-[78%] w-[78%] rounded-full',
              `bg-${notificationDot}`,
            ])}
          ></div>
        </div>
      )}

      {!!statusIcon && (
        <div className="absolute bottom-0 right-0 flex h-[28%] w-[28%] items-center justify-center bg-white">
          {statusIcon}
        </div>
      )}
    </div>
  );
};
