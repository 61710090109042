import * as React from 'react';
import classnames from 'classnames';
import { CheckCircle, Warning } from '@phosphor-icons/react';
import { useState } from 'react';
import { Errors, ErrorsProps } from './Errors';
import { Label } from './Label';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  classNameTextArea?: string;
  errors?: ErrorsProps['errors'];
  id: string;
  label?: string;
  maxLength?: number;
  success?: ErrorsProps['errors'];
}

/**
 * @name TextArea
 * @external https://design.shiftsmart.com/design/textArea
 * @external https://www.figma.com/file/pY05zN0RpIhDwNb0slAe8f/Shiftsmart-Design-System?type=design&node-id=215-10006&mode=dev
 * @description A simple reusable text area component.
 */
export const TextArea = (props: TextAreaProps) => {
  const {
    className,
    classNameTextArea,
    errors,
    required = false,
    id,
    label,
    maxLength,
    onChange,
    success,
    ...rest
  } = props;

  // Hooks
  const [characterCount, setCharacterCount] = useState(0);

  // Setup

  // Handlers
  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;

    setCharacterCount(inputText.length);
    onChange?.(event);
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={className}>
      {!!label && (
        <span className="flex">
          <Label id={id}>{label}</Label>
          {required && <span className="text-error">*</span>}
        </span>
      )}

      <textarea
        aria-describedby={`${id}-error`}
        aria-invalid={!!errors}
        className={classnames(
          'h-full w-full rounded-md p-4',
          'border-gray-7 text-black/50',
          'focus:border-gray-8 focus:text-black/100',
          classNameTextArea,
          {
            'border-gray-7': !errors && !success,
            'border-red': errors,
            'border-success': success,
          },
        )}
        id={id}
        maxLength={maxLength}
        name={id}
        onChange={onChangeHandler}
        required={required}
        {...rest}
      />

      {maxLength && (
        <div className="text-gray-6 mt-2 text-left text-xs">
          {maxLength - characterCount} characters left.
        </div>
      )}

      {errors && (
        <div className="flex items-center py-1">
          <Warning className="text-error h-4 w-4" weight="fill" />
          <Errors
            className="text-error m-0 "
            errors={errors}
            id={`${id}-error`}
          />
        </div>
      )}

      {success && (
        <div className="flex items-center py-1">
          <CheckCircle className="text-success h-4 w-4" weight="fill" />
          <Errors
            className="text-success-light m-0 "
            errors={success}
            id={`${id}-success`}
          />
        </div>
      )}
    </div>
  );
};
