import { useOnMount, useOnScreen } from '@shiftsmartinc/react-hooks';
import type {
  Header,
  Row,
  RowData,
  TableOptions,
  Table as TableType,
} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import { useTable } from '../hooks/useTable';
import { ClassNameComponents, getColumns } from '../utils/table';
import { TableFooter } from './TableFooter';
import { TableRow } from './TableRow';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    classNameComponents?: Pick<ClassNameComponents, 'tbodyCell' | 'theadCell'>;
  }
}

export interface TableProps<Item>
  extends Omit<TableOptions<Item>, 'getCoreRowModel'> {
  /**
   * @description A component displayed on top of headers when any rows are selected.
   * This should generally be a list of buttons to perform bulk actions.
   * For proper scrolling, the parent of this component should be `display: grid`
   */
  HeaderActions?: (props: { table: TableType<Item> }) => JSX.Element;
  activeItemId?: string;
  className?: string;
  classNameComponents?: ClassNameComponents;
  getRowUrl?: (row: Row<Item>) => string;
  loading?: boolean;
  onMount?: (table: TableType<Item>) => void;
  onScrollHitBottom?: (table: TableType<Item>) => void;
  total?: number;
}

/**
 * @name Table
 * @description A fully featured data table using tanstack-table
 * @link http://design.shiftsmart.com/design/tables
 */
export const Table = <Item extends { uuid: string }>(
  props: TableProps<Item>,
) => {
  const {
    className,
    classNameComponents = {},
    data,
    getRowUrl,
    HeaderActions,
    onScrollHitBottom,
    onMount,
    activeItemId,
    state,
    total: totalProp,
    loading = false,
    ...tableOptions
  } = props;

  // Setup (for hooks)
  const total = totalProp ?? data.length;

  const { columns, featureColumns } = getColumns(props);

  // Hooks
  const table = useTable<Item>({
    ...tableOptions,
    columns,
    data,
    state,
    total,
  });

  const infiniteScrollRowRef = useRef<HTMLTableRowElement>(null);
  const isInfiniteScrollRowOnScreen = useOnScreen(infiniteScrollRowRef);

  // Setup
  const columnCount = table.getAllColumns().length;

  const isAnyRowsSelected =
    table.getIsSomeRowsSelected() || table.getIsAllRowsSelected();

  const showFooter = !!state?.pagination || onScrollHitBottom;

  const featureColumnIds = featureColumns.map((column) => column.id);

  const {
    table: classNameTable,
    tableFooter,
    tbody,
    tbodyCell,
    tbodyRow,
    tfoot,
    tfootCell,
    tfootRow,
    thead,
    theadCell,
    theadRow,
  } = classNameComponents ?? {};

  // Handlers

  // Markup
  const divider = (
    <tr className="h-px">
      <th className="!px-0" colSpan={columnCount}>
        <hr className="text-gray-8 w-full" />
      </th>
    </tr>
  );

  const headerActionsRow = HeaderActions && (
    <tr className={classnames('h-0', { invisible: !isAnyRowsSelected })}>
      <th
        className={classnames('invisible m-auto', theadCell)}
        colSpan={featureColumns.length}
      ></th>
      <th
        className={classnames(
          'border-b-gray-8 background-main absolute bottom-0 flex h-full w-fit items-center border-b',
          theadCell,
        )}
        colSpan={columnCount - featureColumns.length}
      >
        <div className="fixed flex items-center gap-2">
          <HeaderActions table={table} />
        </div>
      </th>
    </tr>
  );

  const renderRow = (row: Row<Item>) => (
    <TableRow
      active={!!activeItemId && row.original.uuid === activeItemId}
      classNameComponents={classNameComponents}
      getRowUrl={getRowUrl}
      key={row.id}
      row={row}
      table={table}
    />
  );

  const noDataRow = (
    <tr className={classnames('h-12 border-hidden', tbodyRow)}>
      <td className={tbodyCell} colSpan={columnCount}>
        <div className="m-auto flex h-full w-full items-center justify-center">
          No Data
        </div>
      </td>
    </tr>
  );

  // Life Cycle
  useOnMount(() => {
    onMount?.(table);
  });

  useEffect(() => {
    if (isInfiniteScrollRowOnScreen) {
      onScrollHitBottom?.(table);
    }
  }, [isInfiniteScrollRowOnScreen]);

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'relative grow overflow-y-auto overflow-x-scroll',
        className,
      )}
    >
      <table className={classNameTable}>
        <thead className={thead}>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr className={theadRow} key={headerGroup.id}>
                {headerGroup.headers.map((header: Header<Item, unknown>) => {
                  const invisible =
                    isAnyRowsSelected && !featureColumnIds.includes(header.id);

                  return (
                    <th
                      className={classnames(
                        { invisible },
                        theadCell,
                        header.column.columnDef.meta?.classNameComponents
                          ?.theadCell,
                      )}
                      key={header.id}
                      scope="col"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            );
          })}

          {headerActionsRow}

          {divider}
        </thead>

        <tbody className={tbody}>
          {table.getTopRows().map(renderRow)}

          {table.getCenterRows().map(renderRow)}

          {table.getBottomRows().map(renderRow)}

          {!table.getRowModel().rows.length && noDataRow}
        </tbody>

        {showFooter && (
          <tfoot className={tfoot}>
            <tr
              className={classnames(
                { 'bg-gray-5 animate-pulse': loading },
                tfootRow,
              )}
            >
              <td className={tfootCell} colSpan={columnCount}>
                <TableFooter
                  className={tableFooter}
                  table={table}
                  total={total}
                />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};
