import { useEffect } from 'react';

/**
 * @name useOnMount
 * @description We frequently will triggering "X" on "ComponentDidMount"
 * which is done with "useEffect" and an empty dependency array.
 */
export const useOnMount = (callback: () => void) => {
  // Hooks

  // Setup

  // Handlers

  // Life Cycle

  // 🔌 Short Circuits

  useEffect(() => {
    callback();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
