import classnames from 'classnames';
import { X } from '@phosphor-icons/react';
import { Button } from './Button';

export interface ModalHeaderProps {
  className?: string;
  onClose?: () => void;
  title: string;
}

/**
 * @name ModalHeader
 * @description A simple header with a title and a X button
 */
export const ModalHeader = (props: ModalHeaderProps) => {
  const { className, onClose, title } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'border-gray-8 flex flex-row items-center justify-between border-b px-4 pb-4 pt-5',
        className,
      )}
    >
      <h2 className="text-heading">{title}</h2>
      {onClose && (
        <Button
          className="text-gray-5 hover:text-gray-2 p-0"
          intent="text"
          onClick={onClose}
          type="button"
        >
          <X className="h-6 w-6" />
        </Button>
      )}
    </div>
  );
};
