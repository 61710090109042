import { createContext } from 'react';

export interface ModalContextProps {
  closeModal: (id: string) => void;
  openModal: (id: string) => void;
  openModals: Set<string>;
}

/**
 * @name ModalContext
 * @description Global context that manages an "Set" of "id" via strings,
 * each represents an open modal.
 */
export const ModalContext = createContext<ModalContextProps | undefined>(
  undefined,
);
