import classnames from 'classnames';

export interface TabProps {
  active?: boolean;
  className?: string;
  count?: number;
  disabled?: boolean;
  icon?: JSX.Element;
  label: string;
  onClick?: () => void;
  orientation?: 'horizontal' | 'vertical';
  size?: 'small' | 'medium' | 'large';
  type?: 'pill' | 'underlined';
}

/**
 * @name Tab
 * @description A tab component that can be used in tab bars.
 */
export const Tab = (props: TabProps) => {
  const {
    active = false,
    className,
    count,
    disabled = false,
    icon,
    label,
    onClick,
    orientation = 'horizontal',
    type = 'underlined',
  } = props;

  // TODO: Most probably we'll have to implement predefined sizes.
  // The GlobalTabBar was having 3 different sizes with increased paddings.

  // Hooks

  // Setup
  const isActive = active && !disabled;
  const isPill = type === 'pill';
  const isUnderlined = type === 'underlined';
  const isVertical = orientation === 'vertical';
  const countText = new Intl.NumberFormat('en-US').format(count || 0);
  const showCount = count !== undefined;

  // Handlers
  const onClickHandler = () => {
    if (disabled || isActive) {
      return;
    }

    onClick?.();
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <>
      <div
        className={classnames([
          'text-gray-2 font-500 flex select-none whitespace-nowrap px-3 py-2 transition-colors',
          {
            '!bg-brand-lighter': isPill && isActive,
            '!border-b-0 border-l-2': isUnderlined && isVertical,
            '!border-brand !text-brand': isActive,
            'border-b-2 border-black/10': isUnderlined,
            'hover:bg-asphalt-lighter rounded': isPill && !disabled,
            'hover:border-gray-1 cursor-pointer': !disabled,
            'text-gray-7 cursor-default': disabled,
          },
          className,
        ])}
        onClick={onClickHandler}
        role="tab"
      >
        {icon && <span className="my-auto mr-1">{icon}</span>}
        <span>{label}</span>
        {showCount && (
          <div className="bg-asphalt-lighter font-400 ml-1 flex items-center rounded-full px-1 text-sm leading-4">
            {countText}
          </div>
        )}
      </div>
    </>
  );
};
