import { X } from '@phosphor-icons/react';
import { HeaderContext } from '@tanstack/react-table';
import { Button } from './Button';

export interface TableHeaderPinningProps<Item>
  extends HeaderContext<Item, unknown> {}

/**
 * @name TableHeaderPinning
 * @description A table header to manage cumulative pinning state
 */
export const TableHeaderPinning = <Item extends { uuid: string }>(
  props: TableHeaderPinningProps<Item>,
) => {
  const { table } = props;

  // Hooks

  // Setup

  // Handlers
  const unpinAll = () => table.resetRowPinning();

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <Button
      className="!static !m-auto !p-0"
      data-testid="unpin-all-button"
      intent="text"
      onClick={unpinAll}
    >
      <X />
    </Button>
  );
};
