import { HeaderContext } from '@tanstack/react-table';
import { CaretDown, CaretRight } from '@phosphor-icons/react';
import classnames from 'classnames';
import { Button } from './Button';

export interface TableHeaderExpandingProps<Item>
  extends HeaderContext<Item, unknown> {}

/**
 * @name TableHeaderExpanding
 * @description A table header cell with a button to toggle the cumulative
 * row expansion state and a caret icon to indicate
 * the current cumulative expansion state
 */
export const TableHeaderExpanding = <Item extends { uuid: string }>(
  props: TableHeaderExpandingProps<Item>,
) => {
  const { table } = props;

  // Hooks

  // Setup
  const canSomeRowsExpand = table.getCanSomeRowsExpand();
  const isAllRowsExpanded = table.getIsAllRowsExpanded();

  // Handlers
  const toggleAllRowsExpanded = table.getToggleAllRowsExpandedHandler();

  // Markup
  const Icon = isAllRowsExpanded ? CaretDown : CaretRight;

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className="flex h-full w-full cursor-default items-center justify-center"
      onClick={(event) => event.stopPropagation()}
    >
      {canSomeRowsExpand && (
        <Button
          className={classnames('!rounded-md !p-1.5', {
            'bg-gray-8': isAllRowsExpanded,
            'bg-gray-9': !isAllRowsExpanded,
          })}
          data-testid="expand-all-button"
          intent="text"
          onClick={toggleAllRowsExpanded}
          size="small"
        >
          <Icon />
        </Button>
      )}
    </div>
  );
};
