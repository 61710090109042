import { X, CaretUp, CaretDown } from '@phosphor-icons/react';
import { CellContext, Row } from '@tanstack/react-table';
import { MouseEvent } from 'react';
import { Button } from './Button';

export interface TableCellPinningProps<Item>
  extends CellContext<Item, unknown> {}

/**
 * @name TableCellPinning
 * @description A table cell to manage row pinning state
 */
export const TableCellPinning = <Item extends { uuid: string }>(
  props: TableCellPinningProps<Item>,
) => {
  const { row } = props;

  // Hooks

  // Setup
  const isPinned = row.getIsPinned();

  // Handlers
  const unpin = (event: MouseEvent, row: Row<Item>) => {
    event.stopPropagation();
    row.pin(false, true);
    row.getParentRows().forEach((row) => unpin(event, row));
  };
  const pinTop = (event: MouseEvent) => {
    event.stopPropagation();
    row.pin('top', true, true);
  };
  const pinBottom = (event: MouseEvent) => {
    event.stopPropagation();
    row.pin('bottom', true, true);
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className="flex flex-col gap-1">
      {isPinned ? (
        <Button
          className="!static !p-0"
          data-testid={`unpin-button-${row.id}`}
          intent="text"
          onClick={(event) => unpin(event, row)}
        >
          <X />
        </Button>
      ) : (
        <>
          <Button
            className="!static !p-0"
            data-testid={`pin-top-button-${row.id}`}
            intent="text"
            onClick={pinTop}
          >
            <CaretUp />
          </Button>
          <Button
            className="!static !p-0"
            data-testid={`pin-bottom-button-${row.id}`}
            intent="text"
            onClick={pinBottom}
          >
            <CaretDown />
          </Button>
        </>
      )}
    </div>
  );
};
