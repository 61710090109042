import { useCallback, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Check, UploadSimple as UploadSimpleIcon } from '@phosphor-icons/react';
import classNames from 'classnames';

export interface UploadProps {
  className?: string;
  description?: string;
  disabled?: boolean;
  dropzoneOptions?: Omit<DropzoneOptions, 'onDrop'>;
  label?: string;
  onUpload?: (files: File[]) => void;
  title?: string;
}

/**
 * @name Upload
 * @external https://design.shiftsmart.com/design/upload
 * @external [react-dropzone](https://react-dropzone.js.org)
 * @external [MIME_types](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types)
 * @description A simple reusable upload component that makes use of
 * the "react-dropzone" library.
 */
export const Upload = (props: UploadProps) => {
  const {
    className,
    description,
    disabled,
    dropzoneOptions = {},
    label,
    onUpload,
    title,
  } = props;

  // Hooks
  const [isHover, setIsHover] = useState(false);

  const onDrop = useCallback(
    (files: File[]) => {
      if (disabled) return;
      onUpload?.(files);
    },
    [disabled, onUpload],
  );

  const dropzone = useDropzone({
    noClick: disabled,
    noKeyboard: disabled,
    onDragEnter: () => {
      setIsHover(true);
    },
    onDragLeave: () => {
      setIsHover(false);
    },
    onDrop,

    // The above can be overridden by the caller (sans onDrop)
    ...dropzoneOptions,
  });

  // Setup
  const { acceptedFiles, getRootProps, getInputProps, isFocused } = dropzone;
  const hasAcceptedFiles = acceptedFiles.length > 0;
  const Icon = hasAcceptedFiles ? Check : UploadSimpleIcon;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div>
      {label && <label>{label}</label>}
      <div
        className={classNames(
          'border-gray-7 flex-col rounded-md border border-dashed p-5',
          {
            'bg-brand-lighter border-brand': hasAcceptedFiles,
            'bg-brand-lighter/50 border-brand-lighter': isHover,
            'bg-white': !hasAcceptedFiles && !isHover,
            'focus:border-brand': isFocused,
            'hover:cursor-pointer': !disabled,
          },
          className,
        )}
        {...getRootProps()}
        data-testid="dropzone"
      >
        <input
          data-testid="dropzone-input"
          {...getInputProps({ name: 'upload', type: 'file' })}
        />
        <div className="flex h-full flex-col items-center justify-center gap-4 text-center">
          <div
            className={classNames(
              ' flex h-16 w-16 items-center justify-center rounded-full p-2',
              {
                'bg-blue-300': hasAcceptedFiles,
                'border-gray-7 bg-gray-9': !hasAcceptedFiles,
                'text-gray-7': disabled,
              },
            )}
          >
            <Icon
              className={!hasAcceptedFiles ? 'text-gray-6' : 'text-gray-9'}
              size={32}
              weight="bold"
            />
          </div>

          {title && (
            <div
              className={classNames({
                'text-brand': isFocused || hasAcceptedFiles,
                'text-gray-7': disabled,
              })}
            >
              {title}
            </div>
          )}

          {description && (
            <p className={disabled ? 'text-gray-7' : 'text-gray-5'}>
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
