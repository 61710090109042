import { HeaderContext } from '@tanstack/react-table';
import { Checkbox } from './Checkbox';

export interface TableHeaderCheckboxProps<Item>
  extends HeaderContext<Item, unknown> {}

/**
 * @name TableHeaderCheckbox
 * @description A header cell to display a checkbox for selecting all rows
 */
export const TableHeaderCheckbox = <Item extends object>(
  props: TableHeaderCheckboxProps<Item>,
) => {
  const { table } = props;

  // Hooks

  // Setup
  const isAllRowsSelected = table.getIsAllRowsSelected();
  const isSomeRowsSelected = table.getIsSomeRowsSelected();
  const toggleAllRowsSelected = () => table.toggleAllRowsSelected();

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className="flex h-full w-full cursor-default items-center justify-center"
      onClick={(event) => event.stopPropagation()}
    >
      <Checkbox
        checked={isAllRowsSelected}
        classNameInput="h-6 w-6"
        data-testid="header-checkbox"
        id="selectAll"
        indeterminate={isSomeRowsSelected}
        onChange={toggleAllRowsSelected}
      />
    </div>
  );
};
