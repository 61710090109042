import classnames from 'classnames';
import { CaretDown, X } from '@phosphor-icons/react';
import { Combobox } from '@headlessui/react';
import { Spinner } from '../components/Spinner';

export interface AutocompleteIconProps {
  disabled?: boolean;
  loading?: boolean;
  onClear?: () => void;
  selected?: boolean;
  value?: boolean;
}

export const AutocompleteIcon = (props: AutocompleteIconProps) => {
  const { disabled, loading, onClear, selected, value } = props;

  // Hooks

  // Setups

  // Handlers

  // Markup
  const wrapperClassName = 'absolute inset-y-0 right-0 flex items-center pr-2';

  const iconClassName = classnames(
    'h-5 w-5',
    disabled ? 'content-disabled' : 'content-secondary',
  );

  const buttonClassName = classnames(
    wrapperClassName,
    !disabled && 'cursor-pointer',
  );

  // Short Circuits

  if (loading) {
    return (
      <span
        className={wrapperClassName}
        data-testid="autocomplete-loading-spinner"
      >
        <Spinner className={iconClassName} />
      </span>
    );
  }

  if (value || selected) {
    return (
      <Combobox.Button
        as="button"
        className={buttonClassName}
        data-testid="autocomplete-clear-button"
        onClick={onClear}
      >
        <X className={iconClassName} />
      </Combobox.Button>
    );
  }

  return (
    <Combobox.Button
      as="button"
      className={buttonClassName}
      data-testid="autocomplete-dropdown-button"
    >
      <CaretDown className={iconClassName} />
    </Combobox.Button>
  );
};
