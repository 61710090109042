import { InputHTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';
import { BaseInput } from '../types';

export interface CheckboxProps
  extends BaseInput,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'name' | 'type'> {
  children?: ReactElement | ReactElement[];
  classNameInput?: string;
  indeterminate?: boolean;
  stacked?: boolean;
}

/**
 * @name Checkbox
 * @description A checkbox component
 */
export const Checkbox = (props: CheckboxProps) => {
  const {
    children,
    className,
    classNameInput,
    id,
    indeterminate = false,
    label,
    stacked = false,
    ...rest
  } = props;

  // Hooks

  // Setup

  // Handlers

  /**
   * @external https://stackoverflow.com/questions/32139455/indeterminate-checkbox-in-react-jsx
   * The checkbox input property "indeterminate" can only be set via Javascript,
   * it cannot be directly set as an attribute on the HTMLInputElement
   */
  const refInput = (ref: HTMLInputElement | null) => {
    if (ref) ref.indeterminate = indeterminate;
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <>
      <div
        className={classnames(
          'flex items-start',
          {
            'flex-col-reverse': stacked,
            'gap-3': !stacked,
          },
          className,
        )}
      >
        <div className="flex h-6 items-center">
          <input
            className={classnames(
              'border-gray-7 text-brand focus:ring-gray-4 h-4 w-4 rounded',
              classNameInput,
            )}
            id={id}
            name={id}
            ref={refInput}
            type="checkbox"
            {...rest}
          />
        </div>
        {label && (
          <div className="flex w-full flex-col text-sm leading-6">
            <label className="font-500 block cursor-pointer" htmlFor={id}>
              {label}
            </label>
            {children && <>{children}</>}
          </div>
        )}
      </div>
    </>
  );
};
