import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTimeoutFn } from 'react-use';

export interface LoaderProps {
  className?: string;
  delay?: number;
  loading: boolean;
}

/**
 * @name Loader
 * @description A global loader component to account for the majority of our
 * route level transitions. Passing the `loading` state as a prop lets us
 * nest it anywhere, positioning is generic, make it fixed, sticky, or whatever
 * you need.
 */
export const Loader = (props: LoaderProps) => {
  const { delay = 300, loading, className } = props;

  // Hooks
  const [isLoading, setIsLoading] = useState(loading);
  const [start, cancel, reset] = useTimeoutFn(() => {
    setIsLoading(loading);
  }, delay);

  // Setup

  // Handlers

  // Markup

  // Life Cycle
  useEffect(() => {
    // Restart the delay timer to show the loader
    if (loading) reset();

    // We're not loading, cancel the timer and hide the loader
    if (!loading) {
      cancel();
      setIsLoading(false);
    }
  }, [loading]);

  // 🔌 Short Circuits
  if (loading) start();

  return (
    <div
      className={classnames('ui-loader', { loading: isLoading }, className)}
      data-testid="loader"
    >
      <div className="ui-loader-icon" />
    </div>
  );
};
