import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css?__remix_sideEffect__";
import {
  RenderMonthProps,
  SingleDatePicker,
  SingleDatePickerShape } from
'react-dates';
import { useState, useEffect } from 'react';
import { Errors } from './Errors';
import { Label } from './Label';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type OptionalFields = 'focused' | 'onFocusChange' | 'id';

export type DatePickerProps = PartialBy<SingleDatePickerShape, OptionalFields> &
RenderMonthProps & {
  errors?: string | string[];
  label?: string;
};

/**
 * @name DatePicker
 * @external https://www.npmjs.com/package/react-dates
 * @description Simple date picker for user to choose a single date
 */
export const DatePicker = (props: DatePickerProps) => {
  const {
    errors,
    id = 'date',
    label,
    numberOfMonths = 1,
    showDefaultInputIcon = true,
    isOutsideRange = () => false,
    focused: focusedProp,
    onFocusChange: onFocusChangeProp,
    ...rest
  } = props;

  // Hooks
  const [focused, setFocused] = useState<boolean>(focusedProp ?? false);

  // Setup

  // Handlers
  const onFocusedChange =
  onFocusChangeProp ?? (
  ({ focused }: {focused: boolean;}) => {
    setFocused(focused);
  });

  // Markup

  // Life Cycle
  useEffect(() => {
    if (focusedProp !== focused) setFocused(focusedProp ?? false);
  }, [focusedProp]);

  // 🔌 Short Circuits

  return (
    <div className="flex flex-col">
      {label &&
      <Label className="mb-2" id={id}>
          {label}
        </Label>}


      <SingleDatePicker
        focused={focused}
        id={id}
        isOutsideRange={isOutsideRange}
        numberOfMonths={numberOfMonths}
        onFocusChange={onFocusedChange}
        showDefaultInputIcon={showDefaultInputIcon}
        {...rest} />


      {errors &&
      <Errors
        className="text-red m-0 mt-2"
        errors={errors}
        id={`${id}-error`} />}


    </div>);

};